import * as React from 'react';

import Layout from '../components/layout';
import {useStaticQuery, graphql} from 'gatsby';

import parse from 'html-react-parser';

import '../styles.scss';
import Gallery from '../components/gallery';
import Seo from '../components/seo';


const KapcsolatPage = () => {

  const pageContent = useStaticQuery(graphql`
      query Kapcsolat {
          markdownRemark(frontmatter: {pagename: {eq: "kapcsolat"}}) {
              frontmatter {
                  title,
                  main
                  gallery {
                      text
                      image {
                          childImageSharp {
                              gatsbyImageData
                          }
                      }
                  }
              }
              html
          }
      }
  `);


  const fm = pageContent?.markdownRemark?.frontmatter;
  const html = pageContent?.markdownRemark?.html?.split('[[---GALLERY---]]');
  //
  const heroTitle = fm?.main;
  const images = fm?.gallery;

  return (
    <Layout heroTitle={heroTitle} headerStyle={'waves'}>
      <Seo title={fm?.title} />
      {parse(html[0])}
      {images && (<Gallery images={images}/>)}
      {parse(html[1] ?  html[1] : '')}
    </Layout>
  );
};

export default KapcsolatPage;
